import { Link } from 'gatsby'
import styled from 'styled-components'
import { constants } from 'styles'
import PrevSVG from 'images/prev-icon.svg'
import NextSVG from 'images/next-icon.svg'

// These should be positioned by the parent but
// we can change that if they are used in more thatone place.
const ArrowLink = styled(Link)`
  display: inline-block;
  position: absolute;
  margin-top: 3.4rem;
  background-repeat: no-repeat;
  height: 3rem;
  width: 3rem;
  text-indent: 250%;
  white-space: nowrap;
  overflow: hidden;
  padding: 1rem;
  @media ${constants.breakpoints.maxHeader} {
    width: 2.6rem;
  }
`

export const LeftArrowLink = styled(ArrowLink)`
  left: 3%;
  @media ${constants.breakpoints.maxHeader} {
    left: 10px;
  }
  @media ${constants.breakpoints.mobile} {
    left: 6px;
    margin-top: 1rem;
  }
  background-image: url(${PrevSVG});
`

export const RightArrowLink = styled(ArrowLink)`
  right: 3%;
  @media ${constants.breakpoints.maxHeader} {
    right: 10px;
  }
  @media ${constants.breakpoints.mobile} {
    right: 6px;
    margin-top: 1rem;
  }
  background-image: url(${NextSVG});
`
