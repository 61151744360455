import { FunctionComponent } from 'react'
import styled from 'styled-components'

function getYoutubeLink(youtubeId: string) {
  // We may get a start time spit via a pipe (eg ere7yerer|21)
  const splitId = youtubeId.split('||')
  const startTime = splitId[1] ? `&start=${splitId[1]}` : ''
  return `https://www.youtube-nocookie.com/embed/${splitId[0]}?rel=0&enablejsapi=1${startTime}`
}

const StyledYoutubeWrapper = styled.div`
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;

  .video-iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
`

StyledYoutubeWrapper.displayName = 'StyledYoutubeWrapper'

export interface YoutubeWrapperProps {
  youtubeId: string
  children?: never
  className?: string
}

export const YoutubeWrapper: FunctionComponent<YoutubeWrapperProps> = ({ youtubeId, className }) => (
  <StyledYoutubeWrapper className={className}>
    <iframe
      src={getYoutubeLink(youtubeId)}
      className="video-iframe"
      id="ik_player_iframe"
      style={{
        border: 0,
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
      }}
      allowFullScreen
    />
  </StyledYoutubeWrapper>
)

const StyledYoutubeGrid = styled.div`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
`

StyledYoutubeGrid.displayName = 'StyledYoutubeGrid'

export interface YoutubeGridProps {
  videos: string[]
  children?: never
  className?: string
}

export const YoutubeGrid: FunctionComponent<YoutubeGridProps> = ({ videos, className }) => (
  <StyledYoutubeGrid className={className}>
    {videos.map((youtubeId) => (
      <StyledYoutubeWrapper key={youtubeId}>
        <iframe
          src={getYoutubeLink(youtubeId)}
          className="video-iframe"
          style={{
            border: 0,
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
          allowFullScreen
        />
      </StyledYoutubeWrapper>
    ))}
  </StyledYoutubeGrid>
)
