import { FunctionComponent } from 'react'
import Img from 'gatsby-image'
import styled, { css } from 'styled-components'
import { ContentfulArtistRelease } from 'types'
import { colours, constants } from 'styles'
import { getComponentFromRT } from 'helpers'
import { BandcampLink } from 'components/BandcampLink'
import { YoutubeGrid } from 'components/YoutubeWrapper'

const releaseBreakpoint = 'screen and (max-width: 650px)'
const topSectionPadding = css`
  padding-left: 3rem;
  @media ${constants.breakpoints.maxHeader} {
    padding-left: 2rem;
  }
  @media ${releaseBreakpoint} {
    padding-right: 2rem;
  }
  @media ${constants.breakpoints.mobile} {
    padding-left: 1.2rem;
    padding-right: 1.2rem;
  }
`

const sectionPadding = css`
  ${topSectionPadding}
  padding-right: 3rem;
  @media ${constants.breakpoints.maxHeader} {
    padding-right: 2rem;
  }
`

const ArtistReleaseWrapper = styled.div`
  &::before {
    display: block;
    content: ' ';
    margin-top: -${constants.headerLinkFixHeight};
    height: ${constants.headerLinkFixHeight};
    visibility: hidden;
    pointer-events: none;
  }
  color: ${colours.white};
  :not(:last-of-type) {
    margin-bottom: 4rem;
  }
  > .release-title {
    color: ${colours.darkBlack};
    background-color: ${colours.white};
    ${topSectionPadding}
  }

  > .release-details {
    position: relative;
    background-color: ${colours.darkBlack};
    min-height: 388px;
    padding-bottom: 2rem;

    > .release-image {
      float: right;
      margin-left: 1.6rem;
      width: 40%;
      min-width: 300px;
      @media ${releaseBreakpoint} {
        float: none;
        width: 100%;
        min-width: 0;
        margin-left: 0;
      }
      z-index: 2;
      box-sizing: content-box;
    }

    > .release-date {
      display: inline-block;
      margin-top: 1.6rem;
      font-style: italic;
      ${topSectionPadding}
    }

    > .release-text {
      padding-bottom: 1rem;
      padding-top: 1.5rem;
      ${topSectionPadding}
    }

    > .release-videos {
      clear: both;
      ${sectionPadding}
      margin: 3rem 0;
    }

    > .release-articles {
      padding-bottom: 1rem;
      > h2 {
        color: ${colours.darkBlack};
        background-color: ${colours.white};
        ${sectionPadding}
        margin-top: 2rem;
      }
      > h3 {
        ${sectionPadding}
        font-size: 1.8rem;
        margin-top: 1rem;
      }
      > p {
        ${sectionPadding}
        padding-top: 1rem;
        padding-bottom: 0.2rem;
        &:first-of-type {
          padding-top: 1rem;
        }
      }
      > hr {
        margin-top: 2rem;
      }
    }
    a {
      font-size: 1.6rem;
      font-weight: bold;
      text-transform: uppercase;
      border-bottom: 1px solid ${colours.greyTwo};
      word-break: break-word;
      @media ${constants.breakpoints.mobile} {
        font-size: 1.4rem;
      }
    }
  }
`

const ClearFix = styled.div`
  clear: both;
`

export const ArtistRelease: FunctionComponent<ContentfulArtistRelease> = ({
  title,
  releaseId,
  previewAddress,
  releaseDate,
  longDescription,
  videos,
  articles,
  cover,
}) => (
  <ArtistReleaseWrapper id={releaseId}>
    <h2 className="release-title">{title}</h2>
    <div className="release-details">
      <a className="release-image" href={previewAddress}>
        <Img alt={title} fluid={cover.fluid} />
      </a>
      <span className="release-date">{releaseDate}</span>
      {getComponentFromRT(longDescription, 'release-text')}
      <ClearFix />
      {videos && <YoutubeGrid className="release-videos" videos={videos} />}
      {articles && <section className="release-articles">{getComponentFromRT(articles)}</section>}
    </div>
    {previewAddress && <BandcampLink bandcampUrl={previewAddress} />}
  </ArtistReleaseWrapper>
)
