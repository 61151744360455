import { FunctionComponent } from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled, { css } from 'styled-components'
import { colours, constants, mixins } from 'styles'
import { ArtistRelease } from 'components/ArtistRelease'
import { ContentfulArtist, ContentfulArtistRelease } from 'types'
import { getComponentFromRT } from 'helpers'
import { LeftArrowLink, RightArrowLink } from 'components/ArrowLink'

export const ArtistWrapper = styled.div`
  position: relative;
  ${mixins.innerWrapperMixin}
  margin-bottom: 2rem;
  h1 {
    font-size: 3.4rem;
    font-weight: 600;
    text-align: center;
    padding: 2rem 0;
    @media ${constants.breakpoints.mobile} {
      font-size: 2.6rem;
      padding-top: 0.4rem;
      padding-bottom: 0.4rem;
    }
  }
  h2 {
    font-size: 1.6rem;
    font-weight: 600;
    padding-top: 1rem;
    padding-bottom: 0.8rem;
    text-transform: uppercase;
  }
  h3 {
    font-size: 1.6rem;
    font-weight: 600;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
  }
  p {
    font-size: 1.6rem;
    font-weight: 300;
    padding-top: 0.2rem;
    padding-bottom: 0.8rem;
    line-height: 3rem;
  }
  @media ${constants.breakpoints.mobile} {
    padding-left: 0;
    padding-right: 0;
  }
`

const sectionPadding = css`
  padding-left: 3rem;
  padding-right: 3rem;
  @media ${constants.breakpoints.maxHeader} {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  @media ${constants.breakpoints.mobile} {
    padding-left: 1.2rem;
    padding-right: 1.2rem;
  }
`

const ArtistDetailsWrapper = styled.div<{ primaryColour: string }>`
  .section {
    background: ${colours.greyFive};
    color: ${colours.darkBlack};
    padding-bottom: 1rem;
    > h2 {
      ${sectionPadding}
      background-color: ${(props) => props.primaryColour};
      color: ${colours.white};
      margin-bottom: 1rem;
    }
    > h3 {
      ${sectionPadding}
      background-color: ${colours.greyFour};
      color: ${colours.darkBlack};
    }
    > p {
      ${sectionPadding}
      position: relative;
      strong {
        color: ${colours.greyOne};
      }
    }
    a {
      font-size: 1.6rem;
      font-weight: bold;
      text-transform: uppercase;
      border-bottom: 1px solid ${colours.greyTwo};
      word-break: break-word;
      @media ${constants.breakpoints.mobile} {
        font-size: 1.4rem;
      }
    }
    &.about {
      position: relative;
      border-top-left-radius: 80px;
      overflow: hidden;
      @media ${constants.breakpoints.mobile} {
        border-top-left-radius: 0;
        overflow: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      > .gatsby-image-wrapper {
        float: left;
        width: 300px;
        margin-right: 20px !important;
        margin-bottom: 6px !important;
        border-right: 6px solid ${(props) => props.primaryColour};
        border-bottom: 6px solid ${(props) => props.primaryColour};
        overflow: hidden;
        z-index: 2;
        box-sizing: content-box;
        @media ${constants.breakpoints.mobile} {
          float: none;
          margin: 0 auto !important;
          border: none;
          order: -1;
          width: 100%;
          border: 1rem solid ${(props) => props.primaryColour};
          box-sizing: border-box;
        }
      }
      > h2 {
        position: absolute;
        padding-left: 320px;
        padding-top: 8px;
        padding-bottom: 5px;
        margin-left: 0;
        margin-top: 0;
        width: 100%;
        @media ${constants.breakpoints.mobile} {
          position: static;
          padding: 0 0 0.8rem 1rem;
          margin-bottom: 0;
        }
      }
      > p {
        line-height: 2.4rem;
        margin-bottom: 1rem;
        &:first-of-type {
          margin-top: 6rem;
          @media ${constants.breakpoints.mobile} {
            margin-top: 1rem;
          }
        }
      }
    }
  }
`

function getReleases(releases: ContentfulArtistRelease[], title: string) {
  if (releases.length > 0) {
    return (
      <>
        <h1>{title}</h1>
        {releases.map((rel) => (
          <ArtistRelease key={rel.releaseId} {...rel} />
        ))}
      </>
    )
  }
  return null
}

export interface ArtistLayoutProps {
  data: {
    page: ContentfulArtist
  }
  pageContext: {
    previous: string | null
    next: string | null
  }
}

export const Artist: FunctionComponent<ArtistLayoutProps> = (props) => {
  const { page: artist } = props.data
  const upComingReleases = Array.isArray(artist.releases) ? artist.releases.filter((rel) => rel.isFuture) : []
  const releases = Array.isArray(artist.releases) ? artist.releases.filter((rel) => !rel.isFuture) : []
  return (
    <ArtistWrapper>
      {props.pageContext.previous && <LeftArrowLink to={props.pageContext.previous}>Previous</LeftArrowLink>}
      {props.pageContext.next && <RightArrowLink to={props.pageContext.next}>Next</RightArrowLink>}
      <h1>{artist.name}</h1>
      <ArtistDetailsWrapper primaryColour={artist.primaryColour}>
        <section className="section about">
          <h2>About</h2>
          <Img alt={artist.name} fluid={artist.artistImage.fluid} />
          {getComponentFromRT(artist.about)}
        </section>
        <section className="section">
          <h2>Gear</h2>
          {getComponentFromRT(artist.gear)}
        </section>
      </ArtistDetailsWrapper>

      {getReleases(upComingReleases, 'Upcoming Releases')}
      {getReleases(releases, 'Releases')}
    </ArtistWrapper>
  )
}

export default Artist

export const query = graphql`
  query ArtistQuery($id: String!) {
    page: contentfulArtist(contentful_id: { eq: $id }) {
      name
      primaryColour
      about {
        raw
      }
      gear {
        raw
      }
      artistImage {
        fluid(maxWidth: 640, sizes: "(max-width: 850px) 90vw, (max-width: 1149px) 460px, 320px") {
          ...GatsbyContentfulFluid
        }
      }
      releases: release {
        isFuture
        title
        releaseId
        releaseDate(formatString: "Do MMMM, YYYY")
        previewAddress
        longDescription {
          raw
        }
        videos: youtubeVideos
        articles {
          raw
        }
        cover {
          fluid(maxWidth: 590, sizes: "(max-width: 650px) 94vw, 400px") {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`
